import { ArrowBack, TimerOutlined } from '@mui/icons-material';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField
} from '@mui/material';
import { arrayMoveImmutable } from 'array-move';
import { flatten } from 'lodash';
import moment from 'moment';
import PropTypes, { array } from 'prop-types';
import { useEffect, useState } from 'react';
import { useEvaluationProcesses } from 'src/hooks/useEvaluationProcesses/useEvaluationProcesses';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useSnackbar } from 'src/hooks/useSnackbar/useSnackbar';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import getOrgRef from 'src/utils/getOrgRef';
import SurveyComponent from '../Employees/EmployeeSurvey/SurveyComponent';
import GroupedJobTitlesAndRolesAutoComplete from '../shared/GroupedJobTitlesAndRolesAutoComplete/GroupedJobTitlesAndRolesAutoComplete';
import checkIfQuestionsAlreadyAdded from './checkIfQuestionsAlreadyAdded';
import './evaluation.scss';
import getScoreOfQuestion from './getScoreOfQuestion';
import handleSaveEvaluationProcess from './handleSaveEvaluationProcess';
import NoQuestionsAdded from './NoQuestionsAdded';
import getQuestionDefaultProps from './QuestionsTypes/getQuestionDefaultProps';
import SortableList from './SortableEvaluationList';
import LoadFromOtherSurveySelectField from './LoadFromOtherSurveySelectField';

const AddingNewEvaluationProcess = ({
  isEditting,
  handleGoBack,
  type,
  handleSaveDetails,
  componentName,
  posts
}) => {
  AddingNewEvaluationProcess.propTypes = {
    isEditting: PropTypes.any,
    handleGoBack: PropTypes.func,
    handleSaveDetails: PropTypes.func,
    type: PropTypes.string,
    componentName: PropTypes.string,
    posts: array
  };
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();
  const { evaluationProcesses, setEvaluationProcesses } =
    useEvaluationProcesses();
  const { snackbarState, setSnackbarState } = useSnackbar();

  const initEvalVals = {
    process_name: type === 'knowledgeTest' ? 'מבדק ידע חדש' : 'משוב חדש',
    scoringMethod: {
      type: type === 'knowledgeTest' ? 'allQuestions' : 'onlyRequired'
    }
  };
  const [evaluationProcessValues, setEvaluationProcessValues] =
    useState(initEvalVals);
  const [tabVal, setTabVal] = useState('edit');
  const [alreadySelectedLabels, setAlreadySelectedLabels] = useState();
  const [pageBeingDraggedOver, setPageBeingDraggedOver] = useState();
  const [error, setError] = useState();
  const [newPostGeneralValues, setNewPostGeneralValues] = useState();

  useEffect(() => {
    if (isEditting && typeof isEditting === 'object') {
      const { knowledgeTest } = isEditting;
      setEvaluationProcessValues(knowledgeTest || isEditting);
    }
  }, [isEditting]);
  useEffect(() => {
    if (!alreadySelectedLabels) {
      const mapped = evaluationProcesses.map((evalProcess) => {
        const selectedLabelsInExisting = evalProcess.labels.map(
          (label) => label.id
        );
        return selectedLabelsInExisting.flat(0);
      });
      setAlreadySelectedLabels(flatten(mapped));
    }
  }, [alreadySelectedLabels]);

  const handleChangeView = (newVal) => {
    setTabVal(newVal);
  };

  const handleChangePageTitle = (newTitle, pageIndex) => {
    const temp = { ...evaluationProcessValues };
    temp.pages[pageIndex].name = newTitle;
    temp.pages[pageIndex].title = newTitle;
    temp.pages[pageIndex].nameEditted = newTitle !== `עמוד ${pageIndex + 1}`;
    setEvaluationProcessValues(temp);
  };

  const handleAddNewPage = (currentPageIndex) => {
    const temp = { ...evaluationProcessValues };
    const tempPage = {
      name: `עמוד ${currentPageIndex + 2}`,
      elements: [getQuestionDefaultProps(null, 0, currentPageIndex)]
    };
    temp.pages.splice(currentPageIndex + 1, 0, tempPage);
    const ordered = temp.pages.map((page, index) => ({
      ...page,
      name:
        page.name !== `עמוד ${index + 1}` && page.nameEditted
          ? page.name
          : `עמוד ${index + 1}`,
      order: index
    }));
    setEvaluationProcessValues({ ...temp, pages: ordered });
    setSnackbarState({
      ...snackbarState,
      severity: 'info',
      open: true,
      msg: `עמוד ${currentPageIndex + 2} נוצר בהצלחה`,
      duration: 1500
    });
  };

  const handleDuplicatePage = (currentPageIndex) => {
    const temp = { ...evaluationProcessValues };
    const timestamp = moment().unix();
    const tempPage = {
      name: `עמוד ${currentPageIndex + 2}`,
      elements: [
        ...temp.pages[currentPageIndex].elements.map((question, i) => {
          const newQuestionName = `question${i}-page${
            currentPageIndex + 1
          }-${timestamp}`;
          const newChoices = question.choices
            ? question.choices.map((choice, choiceIndex) => ({
                ...choice,
                value: `p${currentPageIndex + 1}-q${i}-ch${choiceIndex}`
              }))
            : [];

          const newCorrectAnswer = question.correctAnswer
            ? Array.isArray(question.correctAnswer)
              ? question.correctAnswer.map(
                  (answer, choiceIndex) =>
                    `p${currentPageIndex + 1}-q${i}-ch${choiceIndex}`
                )
              : `p${currentPageIndex + 1}-q${i}-ch0`
            : null;

          return {
            ...question,
            name: newQuestionName,
            choices: newChoices,
            correctAnswer: newCorrectAnswer
          };
        })
      ]
    };
    temp.pages.splice(currentPageIndex + 1, 0, tempPage);
    const ordered = temp.pages.map((page, index) => ({
      ...page,
      name:
        page.name !== `עמוד ${index + 1}` && page.nameEditted
          ? page.name
          : `עמוד ${index + 1}`,
      order: index
    }));
    setEvaluationProcessValues({ ...temp, pages: ordered });
    setSnackbarState({
      ...snackbarState,
      severity: 'info',
      open: true,
      msg: `עמוד ${currentPageIndex + 1} שוכפל בהצלחה`,
      duration: 1500
    });
  };

  const handleDeletePage = (currentPageIndex) => {
    const temp = { ...evaluationProcessValues };
    temp.pages.splice(currentPageIndex, 1);
    const ordered = temp.pages.map((page, index) => ({
      ...page,
      name:
        page.name !== `עמוד ${index + 1}` && page.nameEditted
          ? page.name
          : `עמוד ${index + 1}`
    }));
    setEvaluationProcessValues({ ...temp, pages: ordered });
  };
  const updateBeforeSortStart = (e) => {
    e.node.style.opacity = 0.5;
    e.node.style.border = `1px solid ${theme.palette.success.light}`;
    e.node.style.boxShadow = `${theme.shadows[4]}`;
  };
  // const onSortEnd = async ({ oldIndex, newIndex, collection }) => {
  //   const oldCollection = collection;
  //   const newCollection = pageBeingDraggedOver;

  //   const newPages = [...evaluationProcessValues.pages];

  //   // Check if collections (pages) are within bounds
  //   if (
  //     oldCollection < 0 ||
  //     oldCollection >= newPages.length ||
  //     newCollection < 0 ||
  //     newCollection >= newPages.length
  //   ) {
  //     console.error('Invalid collection indices:', {
  //       oldCollection,
  //       newCollection
  //     });
  //     return;
  //   }

  //   // Check if the element is being moved to a different collection (page)
  //   if (oldCollection !== newCollection) {
  //     // Extract the element to be moved
  //     const elementToMove = newPages[oldCollection].elements[oldIndex];
  //     // Remove the element from the source page
  //     newPages[oldCollection].elements = newPages[
  //       oldCollection
  //     ].elements.filter((_, index) => index !== oldIndex);

  //     // Insert the element into the target page at the new index
  //     newPages[newCollection].elements = [
  //       ...newPages[newCollection].elements.slice(0, newIndex),
  //       elementToMove,
  //       ...newPages[newCollection].elements.slice(newIndex)
  //     ];
  //   } else {
  //     // Ensure oldIndex and newIndex are valid within the same collection
  //     if (oldIndex < 0 || oldIndex >= newPages[oldCollection].elements.length) {
  //       console.error('Invalid oldIndex:', { oldIndex });
  //       return;
  //     }
  //     if (newIndex < 0 || newIndex >= newPages[oldCollection].elements.length) {
  //       console.error('Invalid newIndex:', { newIndex });
  //       return;
  //     }

  //     // Move elements within the same page
  //     newPages[oldCollection].elements = arrayMoveImmutable(
  //       newPages[oldCollection].elements,
  //       oldIndex,
  //       newIndex
  //     );
  //   }

  //   // Update the evaluationProcessValues with the new pages array
  //   setEvaluationProcessValues((prevValues) => ({
  //     ...prevValues,
  //     pages: newPages
  //   }));
  // };
  const onSortEnd = async ({ oldIndex, newIndex, collection }) => {
    const oldCollection = collection;
    const newCollection = pageBeingDraggedOver;

    const newPages = [...evaluationProcessValues.pages];

    // Check if collections (pages) are within bounds
    if (
      oldCollection < 0 ||
      oldCollection >= newPages.length ||
      newCollection < 0 ||
      newCollection >= newPages.length
    ) {
      console.error('Invalid collection indices:', {
        oldCollection,
        newCollection
      });
      return;
    }

    // Check if the element is being moved to a different collection (page)
    if (oldCollection !== newCollection) {
      // Extract the element to be moved
      const elementToMove = newPages[oldCollection].elements[oldIndex];
      // Remove the element from the source page
      newPages[oldCollection].elements = newPages[
        oldCollection
      ].elements.filter((_, index) => index !== oldIndex);

      // Insert the element into the target page at the new index
      newPages[newCollection].elements = [
        ...newPages[newCollection].elements.slice(0, newIndex),
        elementToMove,
        ...newPages[newCollection].elements.slice(newIndex)
      ];

      // Update the names of the remaining questions and choices
      newPages[newCollection].elements = newPages[newCollection].elements.map(
        (questionEl, i) => ({
          ...questionEl,
          name: `question${i}-page${newCollection}-${moment().unix()}`,
          choices: questionEl.choices
            ? questionEl.choices.map((choice, choiceIndex) => ({
                ...choice,
                value: `p${newCollection}-q${i}-ch${choiceIndex}`
              }))
            : [],
          correctAnswer: questionEl.correctAnswer
            ? Array.isArray(questionEl.correctAnswer)
              ? questionEl.correctAnswer.map(
                  (answer, choiceIndex) =>
                    `p${newCollection}-q${i}-ch${choiceIndex}`
                )
              : `p${newCollection}-q${i}-ch0`
            : null
        })
      );
    } else {
      // Ensure oldIndex and newIndex are valid within the same collection
      if (oldIndex < 0 || oldIndex >= newPages[oldCollection].elements.length) {
        console.error('Invalid oldIndex:', { oldIndex });
        return;
      }
      if (newIndex < 0 || newIndex >= newPages[oldCollection].elements.length) {
        console.error('Invalid newIndex:', { newIndex });
        return;
      }

      // Move elements within the same page
      newPages[oldCollection].elements = arrayMoveImmutable(
        newPages[oldCollection].elements,
        oldIndex,
        newIndex
      );

      // Update the names of the remaining questions and choices
      newPages[oldCollection].elements = newPages[oldCollection].elements.map(
        (questionEl, i) => ({
          ...questionEl,
          name: `question${i}-page${oldCollection}-${moment().unix()}`,
          choices: questionEl.choices
            ? questionEl.choices.map((choice, choiceIndex) => ({
                ...choice,
                value: `p${oldCollection}-q${i}-ch${choiceIndex}`
              }))
            : [],
          correctAnswer: questionEl.correctAnswer
            ? Array.isArray(questionEl.correctAnswer)
              ? questionEl.correctAnswer.map(
                  (answer, choiceIndex) =>
                    `p${oldCollection}-q${i}-ch${choiceIndex}`
                )
              : `p${oldCollection}-q${i}-ch0`
            : null
        })
      );
    }

    // Update the evaluationProcessValues with the new pages array
    setEvaluationProcessValues((prevValues) => ({
      ...prevValues,
      pages: newPages
    }));
  };
  const onSortMove = (event) => {
    const { clientX, clientY } = event;
    const hoveredElement = document.elementFromPoint(clientX, clientY);
    if (hoveredElement) {
      const hoveredPage = hoveredElement.closest('.questionsPageLayout');
      if (hoveredPage) {
        setPageBeingDraggedOver(
          Number(hoveredPage.getAttribute('data-collection'))
        );
      }
    }
  };

  const handleSavingEvaluationProcess = async (valsToSave, org_ref) => {
    const evalProcessToSave = {
      ...valsToSave,
      process_name:
        valsToSave.process_name ||
        (type === 'knowledgeTest' ? 'מבדק ידע חדש' : 'משוב חדש')
    };
    if (handleSaveDetails) {
      // e.g saving knowledge test and not evaluation
      handleSaveDetails(evalProcessToSave, newPostGeneralValues);
    } else {
      const isExistingEval = Boolean(evalProcessToSave.evaluation_id);
      const data = await handleSaveEvaluationProcess(
        evalProcessToSave,
        org_ref
      );
      if (data) {
        const temp = [...evaluationProcesses];
        const indexOfProcess = temp.findIndex(
          (el) => el.evaluation_id === data.evaluation_id
        );
        const dataToSave = {
          ...data,
          last_modified: {
            seconds: moment().unix()
          }
        };
        if (indexOfProcess > -1) {
          temp[indexOfProcess] = { ...dataToSave };
        } else {
          temp.push({ ...dataToSave });
        }
        setEvaluationProcesses(temp);
        setEvaluationProcessValues(initEvalVals);
        setSnackbarState({
          ...snackbarState,
          open: true,
          severity: 'success',
          msg:
            type === 'knowledgeTest'
              ? 'המבדק נשמר בהצלחה'
              : isExistingEval
              ? 'השינויים במשוב נשמרו בהצלחה'
              : 'המשוב החדש נוסף בהצלחה'
        });
        if (handleGoBack) {
          handleGoBack();
        }
      } else {
        setSnackbarState({
          ...snackbarState,
          open: true,
          severity: 'error',
          msg:
            type === 'knowledgeTest'
              ? 'לא הצלחנו לשמור את המבדק'
              : 'לא הצלחנו לשמור את המשוב'
        });
      }
    }
  };

  const handleChangeEvaluationGroup = (newVals) => {
    // console.log(newVals);
    const temp = { ...evaluationProcessValues };
    temp.labels = newVals.map((val) => {
      const { name, id, item_type } = val;
      return { name, id, item_type };
    });
    setEvaluationProcessValues(temp);
  };

  const handleChangeEvalProcessName = (event) => {
    const temp = { ...evaluationProcessValues };
    temp.name = event.target.value;
    temp.process_name = event.target.value;
    setEvaluationProcessValues(temp);
  };

  const handleChangescoringMethod = (e) => {
    let temp = { ...evaluationProcessValues };
    if (temp.scoringMethod && temp.scoringMethod.type) {
      temp.scoringMethod.type = e.target.value;
    } else {
      temp.scoringMethod = {
        type: e.target.value
      };
    }
    if (temp.pages && temp.pages.length > 0) {
      // console.log(temp.pages);
      temp = getScoreOfQuestion(e.target.value, temp);
      // console.log(tempq);
    }
    setEvaluationProcessValues(temp);
  };

  const handleLoadFromPreviousSurvies = (surveyToLoad) => {
    const {
      knowledgeTest: { process_name, pages, ...rest }
    } = surveyToLoad;

    // Process pages and normalize question names
    const normalizedPages = pages.map((page, pageIndex) => {
      const { id, elements, ...restOfPage } = page;

      // Normalize question names in elements
      const normalizedElements = elements.map((element, questionIndex) => {
        // Generate a timestamp-based suffix for uniqueness
        const timestamp = Date.now();

        // Create new normalized name using page and question indices
        const newName = `question${questionIndex}-page${pageIndex}-${timestamp}`;

        // If the element references its name in other properties (like correctAnswer),
        // we need to update those references too
        const updatedElement = {
          ...element,
          name: newName
        };

        // If this element has choices, ensure their values are updated to match new naming pattern
        if (updatedElement.choices) {
          updatedElement.choices = updatedElement.choices.map(
            (choice, choiceIndex) => ({
              ...choice,
              value: `p${pageIndex}-q${questionIndex}-ch${choiceIndex}`
            })
          );

          // Update correctAnswer values if they exist
          if (updatedElement.correctAnswer) {
            if (Array.isArray(updatedElement.correctAnswer)) {
              // Handle array of correct answers
              updatedElement.correctAnswer = updatedElement.correctAnswer.map(
                (answer) => {
                  const choiceIndex = updatedElement.choices.findIndex(
                    (choice) => choice.text.toLowerCase().includes('correct')
                  );
                  return choiceIndex >= 0
                    ? `p${pageIndex}-q${questionIndex}-ch${choiceIndex}`
                    : answer;
                }
              );
            } else {
              // Handle single correct answer
              updatedElement.correctAnswer = `p${pageIndex}-q${questionIndex}-ch0`;
            }
          }
        }

        return updatedElement;
      });

      return {
        ...restOfPage,
        elements: normalizedElements,
        order: pageIndex
      };
    });

    setEvaluationProcessValues({
      ...evaluationProcessValues,
      ...rest,
      pages: normalizedPages
    });
  };

  const handleChangePreviewAnswers = (event) => {
    setNewPostGeneralValues({
      ...newPostGeneralValues,
      [event.target.name]: event.target.value
    });
  };
  return (
    <Grid container spacing={2}>
      <Grid sx={{ position: 'relative' }} item xs={12}>
        <Tabs value={tabVal} onChange={(e, val) => handleChangeView(val)}>
          <Tab value="edit" label="עריכה" />
          <Tab value="view" label="תצוגה" />
        </Tabs>
        {type !== 'knowledgeTest' && (
          <IconButton
            sx={{ position: 'absolute', right: 15, top: 25 }}
            onClick={() => handleGoBack()}
          >
            <ArrowBack />
          </IconButton>
        )}
      </Grid>
      {tabVal === 'view' && evaluationProcessValues && (
        <SurveyComponent
          surveyJSON={{
            ...evaluationProcessValues,
            title: evaluationProcessValues.process_name,
            mode: 'display'
          }}
        />
      )}
      {tabVal !== 'view' && (
        <Grid spacing={2} item xs={12} container>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={type === 'knowledgeTest' ? 'שם המבדק' : 'שם המשוב'}
                placeholder="תן שם להערכה"
                onChange={(e) => {
                  handleChangeEvalProcessName(e);
                }}
                value={evaluationProcessValues.process_name || ''}
                name="process_name"
                sx={{ maxWidth: 650, ml: 2 }}
              />
            </Grid>
            {type === 'knowledgeTest' && (
              <Grid item xs={12} sx={{ maxWidth: 650, ml: 2 }}>
                <Stack
                  direction="row"
                  // gap={1}
                  alignItems="flex-start"
                  sx={{ maxWidth: 650 }}
                >
                  <Button
                    onClick={() => {
                      const temp = { ...evaluationProcessValues };
                      const newState = !temp.showTimer;

                      temp.showTimer = newState;
                      if (!newState) {
                        delete temp.maxTimeToFinish;
                      }
                      setEvaluationProcessValues(temp);
                    }}
                    endIcon={<TimerOutlined />}
                    role="checkbox"
                    variant={
                      evaluationProcessValues.showTimer
                        ? 'contained'
                        : 'outlined'
                    }
                    sx={{ lineHeight: 1.57, borderRadius: '8px 0px 0px 8px' }}
                    // color={'primary'}
                  >
                    {evaluationProcessValues.showTimer
                      ? 'הטיימר מופעל'
                      : 'הפעלת טיימר'}
                  </Button>

                  <TextField
                    helperText="הקלד בדקות כמה זמן להקציב למבדק - למשל, הזנת 60, המבדק יספור לאחור 60 דקות ואז ייסגר אוטומטית, הזנת 0 יחד עם הפעלת הטיימר תספור כמה זמן לקח לסיים את המבדק"
                    label="זמן בדקות"
                    fullWidth
                    name="maxTimeToFinish"
                    value={
                      evaluationProcessValues.maxTimeToFinish > 0
                        ? evaluationProcessValues.maxTimeToFinish / 60
                        : evaluationProcessValues.maxTimeToFinish === 0
                        ? 0
                        : ''
                    }
                    type="number"
                    onChange={(e) => {
                      const replaced = e.target.value.replace(/\D+/g, '');
                      const value = Number(replaced) * 60 || 0;
                      setEvaluationProcessValues({
                        ...evaluationProcessValues,
                        maxTimeToFinish: value,
                        showTimer: true,
                        showTimerPanel: 'bottom'
                      });
                    }}
                    InputProps={{
                      sx: {
                        borderRadius: '0px 8px 8px 0px'
                      }
                    }}
                  />
                </Stack>
              </Grid>
            )}
            {type === 'knowledgeTest' && (
              <Grow in={type === 'knowledgeTest'}>
                <Grid item xs={12} sx={{ maxWidth: 650, ml: 2 }}>
                  <FormControl sx={{ mt: 1.5, maxWidth: 650 }} fullWidth>
                    <InputLabel id="showAnswers-select-label">
                      הצגת תשובות בעת הסיום
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="showAnswers-select-label"
                      id="showAnswers-select"
                      value={evaluationProcessValues.showAnswers}
                      name="showAnswers"
                      label="הצגת תשובות בעת הסיום"
                      defaultValue={Boolean(false)}
                      onChange={handleChangePreviewAnswers}
                    >
                      <MenuItem value={Boolean(false)}>
                        הסתרת תשובות נכונות
                      </MenuItem>
                      <MenuItem value={Boolean(true)}>
                        כן, ואז נלמד איפה טעינו
                      </MenuItem>
                    </Select>
                    <FormHelperText>
                      האם להציג את התשובות שהנבדק סימן אחרי השליחה?
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grow>
            )}
            {type !== 'knowledgeTest' && (
              <Grid item xs={12}>
                <GroupedJobTitlesAndRolesAutoComplete
                  handleChange={handleChangeEvaluationGroup}
                  value={
                    evaluationProcessValues && evaluationProcessValues.labels
                  }
                  alreadySelectedLabels={alreadySelectedLabels}
                />
              </Grid>
            )}
            {type !== 'knowledgeTest' && (
              <Grid item xs={12}>
                <FormControl fullWidth sx={{ maxWidth: 650, ml: 2 }}>
                  <InputLabel id="grading-method-field-label">
                    איך יינתן הציון?
                  </InputLabel>
                  <Select
                    labelId="grading-method-field-label"
                    id="grading-method-field"
                    name="scoringMethod"
                    // value={getLabelOfscoringMethod(
                    //   evaluationProcessValues.scoringMethod.type
                    // )}
                    value={
                      evaluationProcessValues.scoringMethod?.type ||
                      'allQuestions'
                    }
                    label="איך יינתן הציון?"
                    onChange={(e) => handleChangescoringMethod(e)}
                  >
                    <MenuItem value="onlyRequired">רק שאלות החובה</MenuItem>
                    <MenuItem value="allQuestions">כל השאלות</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {type === 'knowledgeTest' &&
            evaluationProcessValues &&
            (!evaluationProcessValues.pages ||
              evaluationProcessValues.pages.length === 0) && (
              <Grid
                sx={{ display: 'flex', justifyContent: 'center' }}
                item
                xs={12}
              >
                <LoadFromOtherSurveySelectField
                  handleLoadFromPreviousSurvies={handleLoadFromPreviousSurvies}
                  previousSurvies={
                    posts && posts.length > 0
                      ? posts.filter((el) => {
                          if (currentUser.type === 'org') {
                            return el;
                          }
                          return el.author_ref === currentUser.user_doc_id;
                        })
                      : []
                  }
                />
              </Grid>
            )}
          {!checkIfQuestionsAlreadyAdded(evaluationProcessValues) ? (
            <NoQuestionsAdded
              evaluationProcessValues={evaluationProcessValues}
              setEvaluationProcessValues={setEvaluationProcessValues}
              componentName={componentName}
            />
          ) : (
            <>
              <Grid
                item
                xs={12}
                sx={{ justifyContent: 'flex-end', display: 'flex', px: 2 }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    const org_ref = getOrgRef(currentUser, selectedOrg);
                    handleSavingEvaluationProcess(
                      evaluationProcessValues,
                      org_ref
                    );
                  }}
                  disabled={
                    !evaluationProcessValues ||
                    !evaluationProcessValues.pages ||
                    !evaluationProcessValues.pages[0].elements ||
                    evaluationProcessValues.pages[0].elements.length === 0 ||
                    Boolean(error)
                  }
                >
                  {type === 'knowledgeTest' ? 'שמירת מבדק' : 'שמירת משוב'}
                </Button>
              </Grid>
              <SortableList
                updateBeforeSortStart={updateBeforeSortStart}
                useDragHandle
                onSortEnd={onSortEnd}
                onSortMove={onSortMove}
                items={evaluationProcessValues.pages}
                handleAddNewPage={handleAddNewPage}
                handleDeletePage={handleDeletePage}
                handleChangePageTitle={handleChangePageTitle}
                setEvaluationProcessValues={setEvaluationProcessValues}
                handleDuplicatePage={handleDuplicatePage}
                evaluationProcessValues={evaluationProcessValues}
                axis="xy"
                surveyType={type}
                componentName={componentName}
              />
            </>
          )}
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={{
          my: 2,
          px: 2
        }}
      >
        <Stack
          direction="row"
          gap={2}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              const org_ref = getOrgRef(currentUser, selectedOrg);
              handleSavingEvaluationProcess(evaluationProcessValues, org_ref);
            }}
            disabled={
              !evaluationProcessValues ||
              !evaluationProcessValues.pages ||
              !evaluationProcessValues.pages[0] ||
              !evaluationProcessValues.pages[0].elements ||
              evaluationProcessValues.pages[0].elements.length === 0
            }
          >
            {type === 'knowledgeTest' ? 'שמירת מבדק' : 'שמירת משוב'}
          </Button>
          <Button variant="outlined" onClick={handleGoBack}>
            חזרה
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AddingNewEvaluationProcess;
