import { isNaN, uniqueId } from 'lodash';
import { any } from 'prop-types';
import { useEffect, useState } from 'react';
import { Survey } from 'survey-react-ui';
import './answersPreview.css';
import { Backdrop, CircularProgress } from '@mui/material';

const getQuestionCorrectAnswer = (
  surveyJSON,
  questionName,
  questionCorrectAnswer
) => {
  // Parse the question name to extract page and question indices
  if (questionCorrectAnswer) {
    return questionCorrectAnswer;
  }
  const parts = questionName.split('-');
  const questionPart = parts.find((p) => p.startsWith('question'));
  const pagePart = parts.find((p) => p.startsWith('page'));

  if (!questionPart || !pagePart) {
    console.error(`Invalid question name format: ${questionName}`);
    return null;
  }

  const questionIndex = parseInt(questionPart.replace('question', ''), 10);
  const pageIndex = parseInt(pagePart.replace('page', ''), 10);

  // Validate indices
  if (isNaN(pageIndex) || isNaN(questionIndex)) {
    console.error(
      `Invalid indices extracted from question name: ${questionName}`
    );
    return null;
  }

  // Get the page
  const pageObj = surveyJSON.pages[pageIndex];
  if (!pageObj || !pageObj.elements) {
    console.error(`Invalid page index: ${pageIndex}`);
    return null;
  }

  // Get the question
  const questionObj = pageObj.elements[questionIndex];
  if (!questionObj) {
    console.error(
      `Invalid question index: ${questionIndex} for page ${pageIndex}`
    );
    return null;
  }

  return questionObj.correctAnswer;
};

const evaluateAnswer = (
  question,
  userCorrectAnswers,
  questionValue,
  totalQuestions
) => {
  const correctAnswer = userCorrectAnswers || question.correctAnswer;
  if (!correctAnswer) return 0;

  // Handle boolean type questions (true/false)
  if (question.getType() === 'boolean') {
    return questionValue === correctAnswer ? 1 / totalQuestions : 0;
  }

  // Handle radio group questions
  if (question.getType() === 'radiogroup') {
    // Ensure correctAnswer is always treated as array for consistency
    const correctAnswerArray = Array.isArray(correctAnswer)
      ? correctAnswer
      : [correctAnswer];
    return correctAnswerArray.includes(questionValue) ? 1 / totalQuestions : 0;
  }

  // Handle multiple choice questions
  if (Array.isArray(correctAnswer)) {
    if (!questionValue) return 0;

    const userAnswers = Array.isArray(questionValue)
      ? questionValue
      : [questionValue];
    const correctCount = correctAnswer.length;

    // Calculate partial credit for multiple choice
    const correctAnswersCount = userAnswers.filter((answer) =>
      correctAnswer.includes(answer)
    ).length;

    return correctAnswersCount / correctCount / totalQuestions;
  }

  // Handle rating questions
  if (question.getType() === 'rating') {
    if (!questionValue) return 0;
    const normalizedValue =
      (questionValue - question.rateMin) /
      (question.rateMax - question.rateMin);
    return normalizedValue / totalQuestions;
  }

  // Default case: direct comparison
  return questionValue === correctAnswer ? 1 / totalQuestions : 0;
};

// Helper function to update question display
const updateQuestionDisplay = (element, score, totalQuestions) => {
  const header = element.querySelector('h5');
  const span = document.createElement('span');
  span.style.position = 'absolute';
  span.style.left = '10px';

  if (score === 1 / totalQuestions) {
    header.style.background =
      'linear-gradient(to left, #00800061 50%, rgba(0,0,0,0)';
    span.innerHTML = 'נכון';
    span.style.color = 'green';
  } else if (score > 0) {
    header.style.background =
      'linear-gradient(to left, #ffa50085 60%, rgba(0,0,0,0)';
    span.innerHTML = `${(score * 100).toFixed(1)}% נכון`;
    span.style.color = 'orange';
  } else {
    header.style.background =
      'linear-gradient(to left, #fa807275 60%, rgba(0,0,0,0)';
    span.innerHTML = 'לא נכון';
    span.style.color = 'red';
  }

  header.appendChild(span);
};

const updateChoiceDisplays = (question, correctAnswers, userAnswer) => {
  console.log(question);

  const userAnswers = Array.isArray(userAnswer) ? userAnswer : [userAnswer];
  const correctAnswerArray = Array.isArray(correctAnswers)
    ? correctAnswers
    : [correctAnswers];

  // Find the specific question element using its data-name attribute
  const questionElement = document.querySelector(
    `[data-name="${question.name}"]`
  );
  if (!questionElement) return;

  // Navigate down to the content div using the class selector
  const contentDiv = questionElement.querySelector('.sd-element__content');
  if (!contentDiv) return;

  // Find the fieldset within that context
  const fieldset = contentDiv.querySelector('fieldset');
  if (!fieldset) return;

  // Iterate over each choice and update its display
  question.choices.forEach((choice) => {
    // Find input within the fieldset context
    const inputElement = fieldset.querySelector(
      `input[value="${choice.value}"]`
    );
    if (!inputElement) return;

    const labelElement = inputElement.parentElement;
    labelElement.classList.remove('correct', 'incorrect', 'selected');

    const isCorrect = correctAnswerArray.includes(choice.value);
    const isSelected = userAnswers.includes(choice.value);

    if (isCorrect) {
      if (isSelected) {
        labelElement.classList.add('correct', 'selected');
        labelElement.style.color = 'green';
        labelElement.style.fontWeight = 'bold';
        labelElement.style.backgroundColor = '#d4f4dd';
      } else {
        labelElement.classList.add('correct');
        labelElement.style.color = 'blue';
      }
    } else if (isSelected) {
      labelElement.classList.add('incorrect', 'selected');
      labelElement.style.color = 'red';
      labelElement.style.textDecoration = 'line-through';
      labelElement.style.backgroundColor = '#f8d7da';
    } else {
      labelElement.style.color = 'inherit';
    }
  });
};

const ShowCorrectAndIncorrectSurveyAnswers = ({
  survey,
  dataAnswers,
  surveyJSON
}) => {
  ShowCorrectAndIncorrectSurveyAnswers.propTypes = {
    survey: any,
    surveyJSON: any,
    dataAnswers: any
  };

  const [key, setKey] = useState(uniqueId());
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!dataAnswers || !surveyJSON) return;

    setLoading(true);
    survey.data = dataAnswers;
    survey.showNavigationButtons = 'none';
    survey.showProgressBar = 'off';
    survey.showTimerPanel = 'none';
    survey.maxTimeToFinishPage = 0;
    survey.maxTimeToFinish = 0;
    survey.mode = 'display';
    survey.questionsOnPageMode = 'singlePage';

    const totalQuestions = surveyJSON.pages.reduce(
      (acc, page) => acc + page.elements.length,
      0
    );

    survey.onAfterRenderQuestion.add((_, options) => {
      console.log(_);

      const question = survey.getQuestionByName(options.question.jsonObj.name);
      if (!question) return;

      const userCorrectAnswers = getQuestionCorrectAnswer(
        surveyJSON,
        options.question.jsonObj.name,
        options.question.jsonObj.correctAnswer
      );
      const qValue = dataAnswers?.[options.question.jsonObj.name];

      const score = evaluateAnswer(
        question,
        userCorrectAnswers,
        qValue,
        totalQuestions
      );
      updateQuestionDisplay(options.htmlElement, score, totalQuestions);

      if (question.choices) {
        updateChoiceDisplays(question, userCorrectAnswers, qValue);
      }
    });

    setLoading(false);
    setKey(uniqueId());
  }, [dataAnswers, surveyJSON, survey]);

  return (
    <>
      <Survey model={survey} key={key} />
      {isLoading && (
        <Backdrop open={isLoading}>
          <CircularProgress />
        </Backdrop>
      )}
    </>
  );
};

export default ShowCorrectAndIncorrectSurveyAnswers;
