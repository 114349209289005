import { Grid, Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router';

const ArticlePage = ({ article }) => {
  ArticlePage.propTypes = {
    article: PropTypes.any
  };
  // const [article, setArticle] = useState();
  // const location = useLocation();
  const navigate = useNavigate();

  // useEffect(() => {
  //   const selectedArticle = sessionStorage.getItem('article');
  //   if (selectedArticle) {
  //     const parsed = JSON.parse(selectedArticle);
  //     // console.log(parsed);
  //     setArticle(parsed);
  //   }
  //   // console.log(location.state);
  // }, []);
  return (
    <>
      <Helmet>{article && <title>{article.title}</title>}</Helmet>
      <Grid item xs={12} container>
        {!article ? (
          <Skeleton
            variant="rectangular"
            sx={{ width: '100%', minHeight: '500px', zIndex: 1 }}
          />
        ) : (
          <Grid item xs={12} sx={{ mx: 'auto' }}>
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: 0,
                paddingTop: '56.2225%',
                paddingBottom: 0,
                boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
                // margin: 'auto',/
                // marginTop: '1.6em',
                // marginBottom: '0.9em',
                overflow: 'hidden',
                borderRadius: '8px',
                willChange: 'transform'
              }}
            >
              {/* <div
              style={{
                height: '100%',
                position: 'relative',
                overflow: 'hidden',
                willChange: 'transform',
                width: '100%'
              }}
            > */}
              <iframe
                // src="https://app.emaze.com/@ALILOOZIF/prestigious-organization?hidebuttons"
                // src=""
                src={
                  process.env.REACT_APP_ENV === 'dev'
                    ? 'https://show.zohopublic.com/publish/hsjwo37a90f89ee214901a4231e522cdd082f/params?toolbar=true&menu=false&loop=false&viewtype=3&preload=true&embedToolbar=false'
                    : article && article.href
                }
                width="100%"
                height="100%"
                // seamless
                webkitallowfullscreen={Boolean(true)}
                mozallowfullscreen={Boolean(true)}
                allowfullscreen={Boolean(true)}
                // style="border:1px solid #aabbcc;max-width: 100%;"
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  border: 'none',
                  padding: 0,
                  margin: 0
                }}
                scrolling="no"
                title="zoho"
              />
              {/* <iframe
                loading="lazy"
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  border: 'none',
                  padding: 0,
                  margin: 0
                }}
                title="blog"
                src={article && article.href}
                allowFullScreen
                allow="fullscreen"
              /> */}
              {/* </div> */}
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default ArticlePage;
