import {
  collection,
  getDocs,
  limit,
  orderBy,
  query
} from '@firebase/firestore';
import { ArrowBackOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { db } from 'src/_firebase/firebase';
import ShowCorrectAndIncorrectSurveyAnswers from 'src/components/Employees/EmployeeSurvey/ShowCorrectAndIncorrectSurveyAnswers';
import ProgressComponent from 'src/components/Practices/ProgressComponent/ProgressComponent';
import EmptyState from 'src/components/shared/EmptyState/EmptyState';
import { useEmployeesData } from 'src/hooks/useEmployeesData/useEmployeesData';
import { useRbacDetails } from 'src/hooks/useRBAC/useRBAC';
import { useSelectedOrg } from 'src/hooks/useSelectedOrg/useSelectedOrg';
import { useCurrentUserContext } from 'src/hooks/useUserContext/UserContext';
import theme from 'src/theme';
import calcAmountOfEmployeesForPractice from 'src/utils/calcAmountOfEmployeesForPractice';
import { Model } from 'survey-core';
import DataTable from './DataTable';
import getEmployeeDetails from './getEmployeeDetails';
import getUserResponsesOnPost from './getUserResponsesOnPost';

const RowCallbackCustomButton = ({ onClick }) => {
  RowCallbackCustomButton.propTypes = {
    onClick: PropTypes.func
  };

  return (
    <IconButton onClick={onClick}>
      <VisibilityOutlined />
    </IconButton>
  );
};

const PostSignsDialog = ({ post, open, onClose }) => {
  PostSignsDialog.propTypes = {
    post: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func
  };
  const { employeesData } = useEmployeesData();
  const { currentUser } = useCurrentUserContext();
  const { selectedOrg } = useSelectedOrg();
  const { rbacDetails } = useRbacDetails();

  const [postSigns, setPostSigns] = useState();
  const [dataForTable, setDataForTable] = useState();
  const [activeView, setActiveView] = useState('did-sign');
  const [tableHeadCells, setTableHeadCells] = useState([
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'שם העובד',
      sortable: true
    },
    {
      id: 'id',
      numeric: false,
      disablePadding: false,
      label: 'ת.ז',
      sortable: true
    },
    {
      id: 'signed_at',
      numeric: false,
      disablePadding: false,
      date: true,
      label: post.knowledge_test_id ? 'תאריך מענה' : 'תאריך חתימה',
      sortable: true
    }
  ]);
  const [selectedEmployeeResponseData, setSelectedEmployeeResponseData] =
    useState();

  const survey = new Model({
    ...(selectedEmployeeResponseData?.last_response?.process || {}),
    pagePrevText: 'הקודם',
    pageNextText: 'הבא',
    // previewText: 'הצגת תשובות',
    // editText: 'עריכת תשובות',
    showCompletedText: 'סיים'
  });
  survey.locale = 'he';

  const handleRetrievePostSigns = async () => {
    const { post_id, org_ref, knowledge_test_id } = post;

    try {
      let signsDocRef;

      if (knowledge_test_id) {
        // Path for knowledge test signs
        const responsesRef = collection(
          db,
          `orgs/${org_ref}/knowledgeTests/${knowledge_test_id}/responses`
        );

        // Fetch all user response documents
        const responsesSnap = await getDocs(responsesRef);

        if (responsesSnap.empty) {
          // console.log('No responses found.');
          setPostSigns([]);
          return;
        }

        // Fetch the most recent history document for each user
        const userResponsesPromises = responsesSnap.docs.map(
          async (responseDoc) => {
            // console.log(responseDoc.ref.path);

            const historyRef = collection(
              db,
              `${responseDoc.ref.path}/history`
            );
            const recentQuery = query(
              historyRef,
              orderBy('done_at', 'desc'),
              limit(1)
            );

            // Get the last document in the history subcollection
            const historySnap = await getDocs(recentQuery);

            if (historySnap.empty) {
              return null;
            }

            const lastHistoryDoc = historySnap.docs[0].data();
            const userDetails = await getEmployeeDetails(
              responseDoc.id,
              employeesData
            );
            return {
              ...lastHistoryDoc,
              user_id: responseDoc.id,
              response_id: historySnap.docs[0].id,
              sign_id: historySnap.docs[0].id,
              post_id,
              knowledge_test_id,
              user_details: userDetails
            };
          }
        );

        // Resolve all promises and filter out null values
        const resolvedResponses = (
          await Promise.all(userResponsesPromises)
            .then((res) => res)
            .catch((err) => {
              console.error('Error retrieving Promise.all:', err);
            })
        ).filter((response) => response !== null);

        setPostSigns(resolvedResponses);
      } else {
        // Path for community signs
        signsDocRef = collection(
          db,
          `orgs/${org_ref}/community/${post_id}/signs`
        );

        // Fetch the signs
        const signSnap = await getDocs(signsDocRef);

        if (signSnap.empty) {
          setPostSigns([]);
          return;
        }

        // Map over sign documents to fetch their data and user details
        const signsPromises = signSnap.docs.map(async (signDoc) => {
          const signData = signDoc.data();

          // Fetch employee details for the user
          const userDetails = await getEmployeeDetails(
            signDoc.id,
            employeesData
          );

          return {
            ...signData,
            sign_id: signDoc.id,
            user_details: userDetails
          };
        });

        // Resolve all promises and filter out null values
        const resolvedSigns = (await Promise.all(signsPromises)).filter(
          (sign) => sign !== null
        );

        setPostSigns(resolvedSigns);
      }
    } catch (error) {
      console.error('Error retrieving post signs:', error);
      setPostSigns([]);
    }
  };

  useEffect(() => {
    if (post) {
      handleRetrievePostSigns();
    }
  }, [post]);

  const processSignsData = () => {
    if (postSigns) {
      const processed = postSigns.map((sign) => {
        const {
          user_details,
          signed_at,
          done_at,
          post_id,
          org_ref,
          user_id,
          process_answers
        } = sign;
        // console.log(sign);
        const dataToReturn = {
          signed_at: signed_at || done_at,
          name: user_details
            ? `${user_details.first_name} ${user_details.last_name}`
            : 'אין מידע',
          id: user_details ? user_details.id : 'אין מידע',
          user_id,
          org_ref,
          post_id
        };

        if (process_answers) {
          const { totalScore } = process_answers;
          dataToReturn.score = totalScore * 100;
        }
        return dataToReturn;
      });
      return processed;
    }
  };

  useEffect(() => {
    if (post.knowledge_test_id) {
      const temp = [...tableHeadCells];
      if (temp.findIndex((el) => el.id === 'score') === -1) {
        temp.push({
          id: 'score',
          numeric: false,
          disablePadding: false,
          label: 'ציון',
          sortable: true
        });
      }
      setTableHeadCells(temp);
    }
  }, [post]);

  useEffect(() => {
    if (currentUser && selectedOrg && employeesData && postSigns) {
      // console.log(info);
      if (activeView === 'did-not-sign') {
        const t = calcAmountOfEmployeesForPractice(
          postSigns,
          currentUser,
          employeesData,
          selectedOrg,
          post.labels,
          rbacDetails,
          post.allowed_employees
        );
        const { allAuthorizedList } = t;
        const filtered = [];
        const { org_ref, post_id } = post;
        allAuthorizedList.forEach((authEmp) => {
          const { doc_id } = authEmp;
          const indexInSigned = postSigns.findIndex(
            (el) => el.user_id === doc_id
          );
          if (indexInSigned === -1) {
            const { id, first_name, last_name } = authEmp;
            filtered.push({
              id,
              name: `${first_name} ${last_name}`,
              org_ref,
              user_id: doc_id,
              post_id
            });
          }
        });
        console.log(filtered);
        setDataForTable(filtered);
      } else {
        const processed = processSignsData();
        const t = calcAmountOfEmployeesForPractice(
          processed,
          currentUser,
          employeesData,
          selectedOrg,
          post.labels,
          rbacDetails,
          post.allowed_employees
        );
        setDataForTable(processed);
      }
    }
  }, [currentUser, selectedOrg, employeesData, postSigns, activeView]);

  const handleChangeView = (newView) => {
    setActiveView(newView);
  };

  const handleRowClicked = async (val) => {
    const { post_id, user_id, org_ref } = val;
    // /orgs/d1TfSrhEtNNvixM8Sjw0/knowledgeTests/JYWr3642VOILqau7XLa0/responses/RNJSIomnVTsTl4FAKEBh/history/FsoqaGZngyFqOuLooFDC
    const userResponses = await getUserResponsesOnPost(
      org_ref,
      post_id,
      user_id
    );
    const { first_name, last_name, id, avatar, avatar_published } =
      await getEmployeeDetails(user_id, employeesData);
    setSelectedEmployeeResponseData({
      ...userResponses,
      employee_details: { first_name, last_name, id, avatar, avatar_published }
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        {post.knowledge_test_id ? 'מענים על המבדק' : 'חתימות על הפוסט'}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ position: 'relative' }}>
        {post && postSigns ? (
          <>
            <IconButton
              id="toko"
              sx={{
                position: 'absolute',
                right: 25,
                top: 25,
                zIndex: 100
              }}
              onClick={() => setSelectedEmployeeResponseData()}
            >
              <ArrowBackOutlined />
            </IconButton>
            {selectedEmployeeResponseData &&
            selectedEmployeeResponseData.last_response ? (
              <>
                {selectedEmployeeResponseData &&
                  selectedEmployeeResponseData.employee_details && (
                    <ListItem
                      sx={{
                        mb: 2,
                        boxShadow: 3,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: 1,
                        p: 2,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar
                          sx={{ bgcolor: theme.palette.primary.main }}
                          src={
                            selectedEmployeeResponseData.employee_details
                              .avatar_published &&
                            selectedEmployeeResponseData.employee_details.avatar
                          }
                        >
                          {
                            selectedEmployeeResponseData.employee_details
                              .first_name[0]
                          }
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            variant="h6"
                            fontWeight={600}
                            color="textPrimary"
                          >
                            {`${selectedEmployeeResponseData.employee_details.first_name} ${selectedEmployeeResponseData.employee_details.last_name}`}
                            <Typography
                              variant="caption"
                              fontWeight={400}
                              component="span"
                            >
                              {` (${selectedEmployeeResponseData.total} תגובות)`}
                            </Typography>
                          </Typography>
                        }
                        secondary={
                          <>
                            <Typography variant="body2" color="textSecondary">
                              {`תז: ${selectedEmployeeResponseData.employee_details.id}`}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {`נענה בתאריך: ${
                                selectedEmployeeResponseData.last_response
                                  .done_at
                                  ? moment(
                                      selectedEmployeeResponseData.last_response
                                        .done_at.seconds * 1000
                                    ).format('DD/MM/YYYY')
                                  : 'N/A'
                              }`}
                            </Typography>
                          </>
                        }
                      />
                      <ProgressComponent
                        size={60}
                        innerText={`${
                          selectedEmployeeResponseData.last_response
                            .process_answers.score * 100
                        }%`}
                        value={
                          selectedEmployeeResponseData.last_response
                            .process_answers.score * 100
                        }
                        color="primary"
                        customSx={{ mr: 3 }}
                      />
                    </ListItem>
                  )}
                {selectedEmployeeResponseData.last_response.process_answers ? (
                  <ShowCorrectAndIncorrectSurveyAnswers
                    dataAnswers={{
                      ...selectedEmployeeResponseData.last_response
                        .process_answers
                    }}
                    surveyJSON={{
                      ...selectedEmployeeResponseData.last_response.process,
                      mode: 'display',
                      questionsOnPageMode: 'singlePage'
                    }}
                    survey={survey}
                    key="showCorrectAndIncorrectSurveyAnswers-post"
                  />
                ) : (
                  <Typography>נראה שאין תשובות שמורות למענה זה</Typography>
                )}
              </>
            ) : (
              <>
                <Tabs
                  value={activeView}
                  onChange={(e, newView) => handleChangeView(newView)}
                >
                  <Tab
                    value="did-sign"
                    label={post.knowledge_test_id ? 'ענו' : 'חתמו'}
                  >
                    {post.knowledge_test_id ? 'ענו' : 'חתמו'}
                  </Tab>
                  <Tab
                    value="did-not-sign"
                    label={post.knowledge_test_id ? 'לא ענו' : 'לא חתמו'}
                  >
                    {post.knowledge_test_id ? 'לא ענו' : 'לא חתמו'}
                  </Tab>
                </Tabs>
                {postSigns.length > 0 ||
                (dataForTable && dataForTable.length > 0) ? (
                  <DataTable
                    items={dataForTable}
                    tableHeadCells={tableHeadCells}
                    info={post}
                    key="post-signs-table"
                    downlaodedFileName={
                      post.knowledge_test_id
                        ? `${
                            post.process && post.process.process_name
                              ? post.process.process_name
                              : ''
                          } מבדק`
                        : 'חתימות על הפוסט'
                    }
                    rowOnClickCallback={handleRowClicked}
                    rowCallbackCustomButton={RowCallbackCustomButton}
                  />
                ) : (
                  <EmptyState
                    primary="פוסט זה טרם נחתם"
                    secondary="חתימות העובדים יופיעו כאן בטבלה"
                  />
                )}
              </>
            )}
          </>
        ) : (
          <>
            {[1, 2, 3].map((sklt) => (
              <Skeleton
                key={sklt}
                variant="rounded"
                sx={{ width: '100%', height: '52px', my: 1.5 }}
              />
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          ביטול
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PostSignsDialog;
