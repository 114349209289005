import { Dialog, DialogContent, DialogTitle, Divider } from '@mui/material';
import PropTypes from 'prop-types';
import AddNewPostCard from '../AddNewPostCard';

const EditCommunityPostDialog = ({ open, onClose, postToEdit }) => {
  EditCommunityPostDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    postToEdit: PropTypes.object
  };

  const handleSavePostEdits = () => {
    console.log(postToEdit);
  };


  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>עריכת פוסט</DialogTitle>
      <Divider />
      <DialogContent>
        <AddNewPostCard
          existingPostDetails={postToEdit}
          handleSaveNewPost={handleSavePostEdits}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditCommunityPostDialog;
